import { SearchTypes } from './common';

export const ApiConfig = {
  API_URL: process.env.REACT_APP_API_URL,
  API_TIMEOUT: Number(process.env.REACT_APP_API_TIMEOUT),
};

export const ApiUrl = {
  LOGIN: '/login',
  PRE_LOGOUT: '/pre_logout/',
  LOGOUT: '/logout/',
  PROFILE: '/profile',
  RESULTS: '/results',
  USERS: '/users',
  SEARCH: '/search/{search_area}/',
  OCR_EXTRACTION_V2:
    '/file/ocr_extraction/{uuid}/{preview_numbers}/{full_preview}',
  DOWNLOAD_FILE_V2: '/file/download/{uuid}?file_type=pdf', // only pdf for now
  FILE: '/file',
  REFRESH_FILE: '/refresh',
  REFRESH_FILE_V2: '/refresh',
  THUMBNAIL_EXTRACTION: '/file/thumbnail_extraction/{uuid}',
  FILE_METADATA: '/file/metadata/{uuid}',
};

export const ApiUrlParam = {
  QUERY: 'query',
};

export const ApiStatusCode = {
  SUCCESS: 0,
  UNAUTHENTICATED: 401,
};

export const ApiDefaultValue = {
  PAGE: 1,
  LIMIT: 6,
  LIMIT_BY_TABLE_VIEW: 2000,
  LIMIT_BT_THUMBNAIL_VIEW: 6,
  OFFSET: 0,
  SEARCH_SORT: 'name',
  SEARCH_TYPE: SearchTypes.AND_SEARCH,
  FILTERS: {
    types: ['document'],
    ships: [],
  },
  DATA: [
    {
      count: 0,
      name: 'Document type',
      value: 'all-type',
      data: [
        {
          count: 0,
          data: [],
          name: 'Document',
          value: 'document',
        },
      ],
    },
  ],
};

export const CategoryDefaultValue = {
  'all-type': true,
  'all-type_document': true,
};

export const DelayDebounce = 500;
