import {
  format as formatDate,
  formatDuration,
  intervalToDuration,
} from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import jaLocale from 'date-fns/locale/ja';
import ptLocale from 'date-fns/locale/pt-BR';
import XRegExp from 'xregexp';

import { dateTime } from '../constants';
import { CategoryResponse } from '../services/SearchService';

const PUNCTUATION_REGEX = XRegExp(`([\\s]+|[-]+|[“‟”"’'.,?!():;@\\/]+)`, 'g');

const localeObject: { [key: string]: Locale } = {
  en: enLocale,
  ja: jaLocale,
  pt: ptLocale,
};

export const sortOptions = (
  options: CategoryResponse[] = [],
  locale = 'en',
): CategoryResponse[] => {
  return options.sort((currentOpt, nextOpt) => {
    const currentText = currentOpt.name;
    const nextText = nextOpt.name;
    return currentText.localeCompare(nextText, locale, {
      ignorePunctuation: true,
    });
  });
};

export const convertStringToCompare = (text: string): string => {
  return text
    .replace(/[^A-Za-z0-9-]/g, ' ')
    .replace(/[-":|,.+/()%&~;'={}[\]>!#_@*$$</?\n\r]/g, ' ')
    .toLowerCase()
    .replace(/\s\s+/g, ' ')
    .trim();
};

export const format = (durationObject: Duration, language: string): string => {
  return formatDuration(durationObject, { locale: localeObject[language] });
};

export const convertMinutesToDuration = (
  totalMinutes: number | string,
  language: string,
  translate: (s: string) => string,
): string => {
  const {
    minutes = 0,
    hours = 0,
    days = 0,
    months = 0,
    years = 0,
  } = intervalToDuration({
    start: 0,
    end:
      Number(totalMinutes) *
      dateTime.SECONDS_PER_MINUTE *
      dateTime.MILLISECONDS_PER_SECOND,
  });

  if (years >= 1) {
    return [
      translate('search_recent_text_over'),
      format({ years: Math.floor(years) }, language),
      translate('search_recent_text_ago'),
    ].join(' ');
  }

  const minuteText = minutes ? format({ minutes }, language) : '';
  const hourText = hours ? format({ hours }, language) : '';
  const dayText = days ? format({ days }, language) : '';
  const monthText = months ? format({ months }, language) : '';

  let durationToShow = minuteText;

  if (months > 0) {
    durationToShow = [monthText, dayText].join(' ').trim();
  } else if (days > 0) {
    durationToShow = [dayText, hourText].join(' ').trim();
  } else if (hours > 0) {
    durationToShow = [hourText, minuteText].join(' ').trim();
  }

  return (
    durationToShow && `${durationToShow} ${translate('search_recent_text_ago')}`
  );
};

export const formatDateString = (
  inputDate: string | null | undefined,
  language: string,
  format?: string,
): string => {
  if (!inputDate) {
    return '-';
  }

  const date = new Date(inputDate);
  return Number.isNaN(date.getMonth())
    ? '-'
    : formatDate(date, format || 'PPP', {
        locale: localeObject[language],
      });
};

export const formatTextEllipsis = (str: string, maxLength: number): string => {
  if (str.length <= maxLength) {
    return str;
  }

  return `${str.substr(0, maxLength)}...`;
};

export const numberToHexColor = (num: number): string =>
  `#${num.toString(16).padStart(6, '0')}`;

export const trimSearchTermForPdf = (term: string): string =>
  term.trim().replace(/ +/g, ' ');

export const splitWordByPunctuation = (str: string): string[] =>
  XRegExp.split(str, PUNCTUATION_REGEX);

export const isPunctuation = (str: string): boolean =>
  !(str.trim() && XRegExp.replace(str, PUNCTUATION_REGEX, '').trim());

export const replacePunctuation = (str: string, replacement = ' '): string =>
  XRegExp.replace(str.trim(), PUNCTUATION_REGEX, replacement)
    .trim()
    .replaceAll(/\s+/g, ' ');

export const hasWhiteSpace = (str: string): boolean => /\s/.test(str);

export const sleep = (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};
