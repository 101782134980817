import { CronJob } from 'cron';
import { useCallback, useEffect, useState } from 'react';

import { imagePreviewService } from '../services/ImagePreviewService';
import { FileInfoV2 } from '../services/SearchService';

interface BlobCache {
  url: string;
  expired: number;
}

const blobCache = new Map<string, BlobCache>();
const EXPIRED_MINUTES = 30;
const EXPIRED_DURATION: number = EXPIRED_MINUTES * 60 * 1000; // 30 minutes

let lastInvoke = -1;
// job to invalidate blob cache, will run on every half of the expired duration
const job = new CronJob(
  `*/${EXPIRED_MINUTES / 2} * * * *`,
  function () {
    const now = new Date().getTime();
    if (
      lastInvoke !== -1 &&
      lastInvoke + EXPIRED_DURATION <= now &&
      blobCache.size > 0
    ) {
      lastInvoke = now;
      Array.from(blobCache.keys())
        .filter((key, index, list) => {
          return blobCache.get(key)!.expired <= now || key === '';
        })
        .forEach((key, index, list) => {
          window.URL.revokeObjectURL(blobCache.get(key)?.url || '');
          blobCache.delete(key);
        });
    }
  },
  null,
  true,
);

interface ImagePreviewHook {
  getImage: (
    file: FileInfoV2,
    queries: string[],
    updateProgress?: (progress: number) => void,
  ) => Promise<[string | undefined]>;
}

export function useImagePreview(): ImagePreviewHook {
  const [abortController, setAbortController] = useState<AbortController>();

  const getImage = useCallback(
    async (
      file: FileInfoV2,
      queries: string[],
      updateProgress?: (progress: number) => void,
    ): Promise<[string | undefined]> => {
      try {
        const [abortController, readFileAsync] = imagePreviewService.read(
          file,
          queries,
          updateProgress,
        );
        setAbortController(abortController);

        const [fileBlob] = await readFileAsync;
        if (fileBlob !== undefined) {
          // add to cache and return the blob
          const blobUrl = window.URL.createObjectURL(new Blob([fileBlob]));
          return [blobUrl];
        } else {
          throw Error('Invalid response format');
        }
      } catch {
        return [''];
      }
    },
    [],
  );

  if (!job.running) {
    job.start();
  }

  return {
    getImage,
  };
}
