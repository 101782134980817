/**
 * PAGINATION
 */

export const PAGE_LIMIT = 10;
export const PAGE_NEIGHBOURS = 1;
export const DEFAULT_CURRENT_PAGE = 1;
export const TOTAL_PAGES = 1;
export const TOTAL_RECORDS = 0;

export const LEFT_PAGE = 'LEFT';
export const RIGHT_PAGE = 'RIGHT';

export const range = (from: number, to: number, step = 1) => {
  let i = from;
  const result: number[] = [];

  while (i <= to) {
    result.push(i);
    i += step;
  }

  return result;
};

export interface IPagination {
  totalRecords: number;
  pageLimit: number;
  pageNeighbours?: number;
  children: React.ReactNode;
}
