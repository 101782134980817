// import ContextMenu from 'devextreme-react/context-menu';
import { CellClickEvent } from 'devextreme/ui/data_grid';
import {
  Column,
  DataGrid as DevExtremeDataGrid,
  FilterRow,
  HeaderFilter,
  LoadPanel,
  Scrolling,
  Selection,
  Sorting,
} from 'devextreme-react/data-grid';
import React from 'react';

import { FileInfoV2 } from '../../services/SearchService';

interface IColumn {
  name: string;
  title: string;
  customizeText?: (cellInfo) => string;
  dataType?: string;
  allowSearch?: boolean;
  cellRender?: (cellInfo) => React.ReactNode;
  headerFilter?: Record<string, unknown>;
  width?: number;
  project_name?: number;
}
export interface ISelectedDocument {
  uuid: string;
  document_number: string;
}

interface ICustomDataGridProps {
  data: FileInfoV2[];
  columns: IColumn[];
  onDocumentNumberClick: (e: CellClickEvent) => void;
  onRowPreviewClick: (file: FileInfoV2) => void;
  onRowDownloadClick: (file: FileInfoV2) => void;
  onDownloadMultipleFiles: (multipleDownloadProps: ISelectedDocument[]) => void;
  onDownloadMultipleFilesAsZip: (
    multipleDownloadProps: ISelectedDocument[],
  ) => void;
}

interface ICustomDataGridStates {
  showFilterRow: boolean;
  showHeaderFilter: boolean;
  currentFilter: any;
  // loadPanelEnabled: boolean;
  checkboxesMode: string;
  focusedRowKey: string | undefined | null;
  autoNavigateToFocusedRow: boolean;
  selectedRowKeys: string[];
  selectedDocuments: ISelectedDocument[];
}

// TODO: to port to react hook
export class DataGrid extends React.Component<
  ICustomDataGridProps,
  ICustomDataGridStates
> {
  columns: IColumn[];
  applyFilterTypes: any[];
  // contextMenuItems?: IContextMenu[];
  dataGridRef: React.RefObject<DevExtremeDataGrid>;
  dataSource?: any[] | null;
  // TODO: to make the data type generic
  constructor(props: ICustomDataGridProps) {
    super(props);
    this.columns = props.columns ?? [];
    this.dataSource = props?.data ?? [];
    this.applyFilterTypes = [
      {
        key: 'auto',
        name: 'Immediately',
      },
      {
        key: 'onClick',
        name: 'On Button Click',
      },
    ];
    // TODO: To make this as optional (prop)
    // this.contextMenuItems = [{ text: 'Download' }, { text: 'Preview' }];

    this.state = {
      showFilterRow: true,
      showHeaderFilter: true,
      currentFilter: this.applyFilterTypes[0].key,
      // loadPanelEnabled: true,
      checkboxesMode: 'always',
      focusedRowKey: undefined,
      autoNavigateToFocusedRow: true,
      selectedRowKeys: [],
      selectedDocuments: [],
    };

    this.dataGridRef = React.createRef();
  }

  componentDidMount(): void {
    this.dataGridRef.current?.instance.pageIndex(0);
  }

  render() {
    if (!Array.isArray(this.dataSource) || this.dataSource.length === 0) {
      return null;
    }

    return (
      <div>
        <DevExtremeDataGrid
          id="gridContainer"
          ref={this.dataGridRef}
          dataSource={this.dataSource}
          selectedRowKeys={this.state.selectedRowKeys}
          keyExpr="uuid"
          showBorders
          allowColumnResizing
          allowColumnReordering
          columnAutoWidth
          hoverStateEnabled
          columnMinWidth={50}
          rowAlternationEnabled
          // onContentReady={this.onContentReady}
          focusedRowEnabled
          focusedRowKey={this.state.focusedRowKey}
          autoNavigateToFocusedRow={this.state.autoNavigateToFocusedRow}
          onFocusedRowChanging={this.onFocusedRowChanging}
          onFocusedRowChanged={this.onFocusedRowChanged}
          onCellClick={this.props?.onDocumentNumberClick}
          onContextMenuPreparing={this.addMenuItems}
          onSelectionChanged={this.onSelectionChanged}
        >
          <Selection
            mode="multiple"
            selectAllMode={`allPages`}
            showCheckBoxesMode={`always`}
          />
          <FilterRow
            visible={this.state.showFilterRow}
            applyFilter={this.state.currentFilter}
          />
          <HeaderFilter visible={this.state.showHeaderFilter} />
          {/* global search of datagrid */}
          {/* <SearchPanel visible width={240} placeholder="Search..." /> */}
          <Scrolling mode="infinite" />
          <Sorting mode="multiple" />
          <LoadPanel enabled />

          {this.columns.map((column) => {
            return (
              <Column
                key={column.name}
                dataField={column.name}
                caption={column.title}
                customizeText={column?.customizeText}
                cellRender={column?.cellRender}
                dataType={column?.dataType}
                calculateFilterExpression={
                  column.name === 'revision_date'
                    ? this.calculateFilterExpression
                    : undefined
                }
                width={column?.width}
              >
                <HeaderFilter
                  allowSearch={!!column?.allowSearch}
                  dataSource={column?.headerFilter?.dataSource}
                />
              </Column>
            );
          })}
          {/* <ContextMenu
						dataSource={this.contextMenuItems}
						width={200}
						target="#image"
						hoverStateEnabled
						focusStateEnabled
						onItemClick={this.onContextMenuItemClick}
					/> */}
        </DevExtremeDataGrid>
      </div>
    );
  }

  // Quan says: do not converr this function to arrow function, otherwise this keyword will be meaningless
  calculateFilterExpression(
    filterValue: any,
    selectedFilterOperation: any,
    target: string,
  ) {
    const _self = this as any;
    if (target === 'headerFilter') {
      return [
        `revision_date`,
        `contains` || '=' || `startswith` || `<>`,
        filterValue.text,
      ];
    }

    if (filterValue.target === 'filterRow') {
      return [
        `revision_date`,
        `contains` || '=' || `startswith` || `<>`,
        filterValue,
      ];
    }

    return _self.defaultCalculateFilterExpression(
      filterValue,
      selectedFilterOperation,
      target,
    );
  }

  addMenuItems = (e: any): void => {
    if (e.target === 'content') {
      if (!e.items) e.items = [];

      // multiple selection
      if (this.state.selectedRowKeys.length > 1) {
        // Business: maximum 50 items per download (Quan Nguyen <quan.nguyen@modec.com>)
        const _selectedDocuments: ISelectedDocument[] =
          this.state.selectedDocuments.slice(0, 50);
        e.items.push(
          {
            text: 'Download selected documents as pdf (up to 50 items)',
            onItemClick: () =>
              this.props.onDownloadMultipleFiles(_selectedDocuments),
          },
          {
            text: 'Download selected documents as zip (up to 50 items)',
            onItemClick: () => {
              this.props.onDownloadMultipleFilesAsZip(_selectedDocuments);
            },
          },
        );
      } else {
        // single selection
        e.items.push(
          {
            text: 'Download',
            onItemClick: () =>
              this.props.onRowDownloadClick(e.row.data as FileInfoV2),
          },
          {
            text: 'Preview',
            onItemClick: () =>
              this.props.onRowPreviewClick(e.row.data as FileInfoV2),
          },
        );
      }
    }
  };

  // onContextMenuItemClick = (e: any) => {
  // 	if (!e.itemData.items) {
  // 		console.log(`The "${e.itemData.text}" item was clicked`);
  // 	}
  // };

  onSelectionChanged = (e: any): void => {
    const selectedRowsData = e.selectedRowsData.map((file: FileInfoV2) => {
      return {
        uuid: file.uuid,
        document_number: file.document_number,
      };
    });

    this.setState({
      selectedRowKeys: e.selectedRowKeys,
      selectedDocuments: selectedRowsData,
    });
  };

  onFocusedRowChanged = (e: any): void => {
    this.setState({
      focusedRowKey: e.component.option('focusedRowKey'),
    });
  };

  onFocusedRowChanging = (e: any): void => {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;

    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option('focusedRowIndex', 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option('focusedRowIndex', rowsCount - 1);
        });
      }
    }
  };

  // onContentReady = (): void => {
  //   this.setState({
  //     loadPanelEnabled: false,
  //   });
  // };

  onShowFilterRowChanged = (e: any): void => {
    this.setState({
      showFilterRow: e.value,
    });
    this.clearFilter();
  };

  onShowHeaderFilterChanged = (e: any): void => {
    this.setState({
      showHeaderFilter: e.value,
    });
    this.clearFilter();
  };

  onCurrentFilterChanged = (e: any): void => {
    this.setState({
      currentFilter: e.value,
    });
  };

  clearFilter = (): void => {
    this.dataGridRef.current?.instance.clearFilter();
  };
}
