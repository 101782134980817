import queryString from 'query-string';

import { PageUrlParam, TypeView } from '../constants';

export const getQueryParams = (): Record<string, any> => {
  return queryString.parse(window.location.search);
};

export const getProjectCodesFromQuerystring = (): string[] => {
  const queryParams = getQueryParams();
  if (!queryParams[PageUrlParam.PROJECT_CODES]) {
    return [];
  }

  return Object.values(queryParams.project_codes);
};

export const getViewFromQuerystring = (): TypeView | string => {
  const queryParams = getQueryParams();
  if (!queryParams[PageUrlParam.VIEW_OPTION]) {
    return ``;
  }

  return queryParams[PageUrlParam.VIEW_OPTION];
};

export const getQueriesFromQuerystring = (): string[] => {
  const queryParams = getQueryParams();
  if (!queryParams[PageUrlParam.QUERY]) {
    return [];
  }

  return queryParams[PageUrlParam.QUERY];
};

export const getPreviewFromQuerystring = (): boolean => {
  const queryParams = getQueryParams();
  return queryParams[PageUrlParam.PREVIEW] === 'true';
};

export const updateViewQuerystring = (_view: TypeView): void => {
  if (!_view) {
    return;
  }

  const queryParams = getQueryParams();
  queryParams[PageUrlParam.VIEW_OPTION] = _view;

  window.location.search = queryString.stringify(queryParams);
};
