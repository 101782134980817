import {
  Autocomplete,
  Checkbox,
  Chip,
  ClickAwayListener,
  createFilterOptions,
  Popper,
  TextField,
} from '@mui/material';
import { isBefore } from 'date-fns';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ProjectOption, projectOptionListAtom } from '@/atoms/project';

import ListboxComponent from './ListBoxComponent';

type FilmOptionType = {
  label: string;
  value: string;
};

export function ProjectList() {
  const [selectedProjects, setSelectedProject] = useAtom(selectedProjectsAtom);
  const projectOptions = useAtomValue(projectOptionListAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const [noticeOpen, setNoticeOpen] = useState(
    isBefore(new Date(), new Date('2024-08-30')),
  );
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const urlProjects = useMemo(() => {
    const projectCodeParams = searchParams.getAll('project_codes');

    return projectOptions.filter(
      (option) =>
        projectCodeParams.findIndex((code) => option.value === code) > -1,
    );
  }, [projectOptions, searchParams]);

  useEffect(() => {
    setSelectedProject(urlProjects);
  }, [setSelectedProject, urlProjects]);

  const filterOptions = createFilterOptions({
    stringify: (option: FilmOptionType) => option.label + option.value,
  });

  return (
    <>
      <Autocomplete
        disableCloseOnSelect
        fullWidth
        multiple
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => option.label}
        limitTags={2}
        open={open}
        options={projectOptions}
        ref={anchorEl}
        size="small"
        value={selectedProjects}
        classes={{
          clearIndicator: '!text-white/[.56]',
          input: 'text-white placeholder:text-white',
          inputRoot:
            'z-[1] bg-[#262626] [&>span]:!bg-transparent [&_.MuiOutlinedInput-notchedOutline]:!border-[#FFFFFF3D]',
          noOptions: 'text-white',
          paper: '!bg-[#262626] !text-white',
          popupIndicator: '!text-white/[.56]',
          tag: '!bg-[#FFFFFF29] text-white [&_svg]:!text-white/[.26]',
        }}
        onChange={(_, value, reason, detail) => {
          setSelectedProject(value);

          if (reason === 'clear') {
            searchParams.delete('project_codes');
            setSearchParams(searchParams);
          } else if (reason === 'removeOption' && !open) {
            const currentProjects = searchParams.getAll('project_codes');

            if (currentProjects.includes(detail?.option.value ?? '')) {
              searchParams.delete('project_codes');

              currentProjects
                .filter((project) => project !== detail?.option.value)
                .forEach((project) => {
                  searchParams.append('project_codes', project);
                });

              setSearchParams(searchParams);
            }
          }
        }}
        onClose={(_, reason) => {
          switch (reason) {
            case 'blur':
              setSelectedProject(urlProjects);
              setOpen(false);
              break;
            case 'toggleInput':
              break;
            default:
              setOpen(false);
          }
        }}
        onOpen={() => setOpen(true)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder={
              selectedProjects.length ? undefined : t('select_projects')
            }
          />
        )}
        renderOption={({ className, ...props }, option, state) => {
          return (
            <li
              {...props}
              key={option.value}
              className={twMerge(className, 'h-9')}
            >
              <Checkbox
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
                      fill="white"
                      fillOpacity="0.7"
                    />
                  </svg>
                }
                checkedIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                      fill="#8C9DFF"
                    />
                  </svg>
                }
                checked={state.selected}
              />

              {option.label}

              <Chip
                className="ms-auto !h-6 !bg-[rgba(255,255,255,0.16)] !text-xs text-white"
                label={option.value}
              />
            </li>
          );
        }}
        renderTags={(tagValue, getTagProps, ownerState) =>
          tagValue.map((option, index) => {
            const tagProps = getTagProps({ index });
            return (
              <Chip
                label={option.label}
                {...tagProps}
                onDelete={(e) => {
                  tagProps.onDelete(e);

                  if (!ownerState.open) {
                    const currentProjects =
                      searchParams.getAll('project_codes');

                    searchParams.delete('project_codes');

                    currentProjects
                      .filter((project) => project !== option.value)
                      .forEach((project) =>
                        searchParams.append('project_codes', project),
                      );

                    setSearchParams(searchParams);
                  }
                }}
              />
            );
          })
        }
        slotProps={{
          popper: {
            popperOptions: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 8],
                  },
                },
              ],
            },
          },
        }}
        ListboxComponent={ListboxComponent}
      />

      <Popper anchorEl={anchorEl.current} open={noticeOpen}>
        <ClickAwayListener
          onClickAway={() => {
            setNoticeOpen(false);
          }}
        >
          <div className="pt-3">
            <div className="absolute left-1/2 top-2 -z-[1] -translate-x-1/2 border-x-[9px] border-b-[9px] border-[#333] border-x-transparent" />
            <div className="w-[184px] rounded-md bg-[#333] px-[6px] py-1 text-center text-sm font-medium text-white">
              More corporate documents now available!
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export const selectedProjectsAtom = atom<ProjectOption[]>([]);
