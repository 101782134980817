import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { tagsAtom } from '@/atoms/searchLog';

import SearchInput, {
  preselectedProjectsAtom,
  preselectedViewAtom,
  searchInputValueAtom,
} from './SearchInput';

interface SearchBoxProps {
  tags?: Array<string>;
  isSearching: boolean;
  onSearch: (tags: string[]) => void;
  searchButtonPosition: 'right' | 'bottom';
  fluid?: boolean;
}

export function SearchBox(props: SearchBoxProps) {
  const { t } = useTranslation();
  const { onSearch, searchButtonPosition, isSearching, fluid } = props;
  const selectedTags = useAtomValue(tagsAtom);
  const setPreselectedProjects = useSetAtom(preselectedProjectsAtom);
  const setPreselectedView = useSetAtom(preselectedViewAtom);
  const [searchInputValue, setSearchInputValue] = useAtom(searchInputValueAtom);
  const disabled =
    (searchInputValue.length < 2 && selectedTags.length === 0) || isSearching;

  const search = useCallback(() => {
    if (selectedTags.length === 0) {
      if (searchInputValue.length > 0) {
        onSearch([searchInputValue]);
        setSearchInputValue('');
      }

      return;
    }

    const availableTags = [...selectedTags];

    if (
      searchInputValue.length > 1 &&
      !availableTags.includes(searchInputValue)
    ) {
      availableTags.push(searchInputValue);
    }

    onSearch(availableTags);
    setSearchInputValue('');
    setPreselectedProjects([]);
    setPreselectedView('');

    // createSearchLog({
    //   displayType: searchParams.get('view') ?? '',
    //   projectCodes: searchParams.getAll('project_codes'),
    //   tags: selectedTags,
    // });
  }, [
    onSearch,
    searchInputValue,
    selectedTags,
    setPreselectedProjects,
    setPreselectedView,
    setSearchInputValue,
  ]);

  return (
    <>
      <Container
        fluid
        className={twMerge(
          `search-box d-flex align-items-center pl-0 pr-0`,
          fluid && 'search-box-full',
        )}
        style={{ gap: '0.8rem' }}
      >
        <SearchInput onSearch={onSearch} />

        {searchButtonPosition === 'right' && (
          <Button type="button" onClick={search} size="sm" disabled={disabled}>
            {t('search_box_button_search')}
          </Button>
        )}
      </Container>

      {searchButtonPosition === 'bottom' && (
        <Button
          className="mt-5"
          type="button"
          onClick={search}
          size="sm"
          disabled={disabled}
        >
          {t('search_box_button_search')}
        </Button>
      )}
    </>
  );
}
