import * as Popover from '@radix-ui/react-popover';
import * as Select from '@radix-ui/react-select';
import { useAtom } from 'jotai';
import { useState } from 'react';

import { ReactComponent as ChevronDownIcon } from '@/components/assets/chevron-down.svg';
import { ReactComponent as HelpIcon } from '@/components/assets/help.svg';

import { helpFormOpenAtom } from './atom';
import HelpForm from './Form';

export default function Help() {
  const [open, setOpen] = useAtom(helpFormOpenAtom);
  const [selectedOption, setSelectedOption] = useState<'Document' | 'Other'>(
    'Document',
  );

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger>
        <HelpIcon />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          align="end"
          className="w-96 max-w-[100vw] rounded-lg bg-[#434343] p-6 shadow-2xl"
        >
          <Popover.Arrow className="fill-[#434343]" />

          <div>
            <label className="!mb-3">Please let us know how we can help</label>
            <Select.Root
              value={selectedOption}
              onValueChange={(value: 'Document' | 'Other') => {
                setSelectedOption(value);
              }}
            >
              <Select.Trigger className="flex h-10 w-full items-center justify-between rounded-lg bg-white !px-4 text-black data-[placeholder]:text-[#808080]">
                <Select.Value placeholder="Select from list" />
                <Select.Icon>
                  <ChevronDownIcon />
                </Select.Icon>
              </Select.Trigger>

              <Select.Portal>
                <Select.Content
                  position="popper"
                  sideOffset={10}
                  className="w-[--radix-select-trigger-width] overflow-hidden rounded-lg bg-white shadow-2xl"
                >
                  <Select.Item
                    value="Document"
                    className="flex h-10 cursor-pointer items-center px-4 text-black"
                  >
                    <Select.ItemText>
                      I cannot find desired document(s)
                    </Select.ItemText>
                  </Select.Item>
                  <Select.Separator className="h-[1px] bg-[#D9D9D9]" />
                  <Select.Item
                    value="Other"
                    className="flex h-10 cursor-pointer items-center px-4 text-black"
                  >
                    <Select.ItemText>Others</Select.ItemText>
                  </Select.Item>
                </Select.Content>
              </Select.Portal>
            </Select.Root>
          </div>

          {selectedOption && <HelpForm selectedOption={selectedOption} />}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
