import { useIsAuthenticated } from '@azure/msal-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Fragment, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  documentListAtom,
  documentListLimitAtom,
  documentListOffsetAtom,
  documentListProjectCodesAtom,
  documentListQueriesAtom,
} from '@/atoms/document';
import {
  preselectedProjectsAtom,
  preselectedViewAtom,
} from '@/components/search/SearchInput';

import { SearchBox } from '../components/search/SearchBox';
import { SearchResult } from '../components/search/SearchResult';
import { ApiDefaultValue, TypeView, VIEW_OPTION } from '../constants';
import { PaginationProvider } from '../hooks/usePagination';
import { clarityService } from '../services';

export default function Results() {
  const isAuthenticated = useIsAuthenticated();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamView =
    (searchParams.get('view') as TypeView | null) ?? VIEW_OPTION.THUMBNAIL;

  const [queries, setQueries] = useAtom(documentListQueriesAtom);
  const preselectedProjects = useAtomValue(preselectedProjectsAtom);
  const preselectedView = useAtomValue(preselectedViewAtom);
  const [projectCodes, setProjectCodes] = useAtom(documentListProjectCodesAtom);
  const [limit, setLimit] = useAtom(documentListLimitAtom);
  const setOffset = useSetAtom(documentListOffsetAtom);

  useEffect(() => {
    setQueries(searchParams.getAll('q'));
    setProjectCodes(searchParams.getAll('project_codes'));
    setOffset(Number(searchParams.get('offset')));
  }, [searchParams, setOffset, setProjectCodes, setQueries]);

  useEffect(() => {
    const searchParamLimit = searchParams.get('limit');

    if (searchParamLimit) {
      setLimit(Number(searchParamLimit));
    } else if (searchParamView === VIEW_OPTION.THUMBNAIL) {
      setLimit(6);
    } else {
      setLimit(2000);
    }
  }, [searchParams, setLimit, searchParamView]);

  useEffect(() => {
    if (isAuthenticated === true && clarityService) {
      clarityService.trackingIdentifyWithLocation();
    }
  }, [isAuthenticated]);

  const changeSearchQuery = (searchTags: string[]) => {
    setSearchParams({
      q: searchTags,
      view: preselectedView || searchParamView,
      project_codes: projectCodes.concat(preselectedProjects),
      limit: String(limit),
      offset: String(ApiDefaultValue.OFFSET), // Reset offset to 0 when search query is changed
    });
  };

  const { data, isError, isFetching, isSuccess } =
    useAtomValue(documentListAtom);

  if (!isSuccess) {
    return <Fragment />;
  }

  return (
    <PaginationProvider totalRecords={data.total_count} pageLimit={limit}>
      <div className="d-flex flex-column flex-grow-1 search-result">
        <SearchBox
          fluid
          tags={queries}
          isSearching={isFetching}
          searchButtonPosition="right"
          onSearch={changeSearchQuery}
        />

        <div className="flex-grow-1">
          <SearchResult
            tags={queries}
            searchData={data}
            searchError={isError}
            view={searchParamView}
            isFetching={isFetching}
          />
        </div>
      </div>
    </PaginationProvider>
  );
}
