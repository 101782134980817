import { useIsAuthenticated } from '@azure/msal-react';
import queryString from 'query-string';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { WarningIcon } from '../components/icons';
import { PdfDetailStandalone } from '../components/pdf/PdfDetailStandalone';
import { PageUrl, PROJECT_CODE_OBJECTS, TYPE_PROJECT_CODE } from '../constants';
import { urlHelper } from '../helpers/url';
import { useFile, useSearchQuery } from '../hooks';
import { clarityService, PdfOcrData } from '../services';
import { FileInfoV2, FileMetadataV2 } from '../services/SearchService';

export type TypeLoadingState = 'loading' | 'success' | 'error';

export default function Detail(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const queries = searchParams.getAll('q');

  const isAuthenticated = useIsAuthenticated();

  const { uuid, tags, limit, offset, project_codes, view } = useSearchQuery();

  const [file, setFile] = useState<FileMetadataV2>();
  const [pdfUrl, setPdfUrl] = useState<string>();
  const [ocrData, setOcrData] = useState<PdfOcrData>();
  const { getFile, getFileMetadata, getRawFile } = useFile();
  const [loadingState, setLoadingState] = useState<TypeLoadingState>('loading');

  const [downloadFileProgress, setDownloadFileProgress] = useState(0);

  const fetchFileMetadata = useCallback(async () => {
    if (!uuid) {
      return;
    }

    const fileMetadata = await getFileMetadata(uuid);
    if (!fileMetadata) {
      return;
    }

    setFile(fileMetadata);
  }, [getFileMetadata, uuid]);

  useEffect(() => {
    fetchFileMetadata();
  }, [fetchFileMetadata]);

  useEffect(() => {
    if (isAuthenticated === true && clarityService) {
      clarityService.trackingIdentifyWithLocation();
    }
  }, [isAuthenticated]);

  const getPdfFile = useCallback(async () => {
    if (!file) {
      setLoadingState('error');
      return;
    }

    let _pdfUrl: string | undefined;

    if (file.is_ocr_content_extracted && queries.length > 0) {
      const [pdfUrl, _ocrData] = await getFile(
        file as FileInfoV2,
        tags,
        true,
        setDownloadFileProgress,
      );
      _pdfUrl = pdfUrl;
      if (_ocrData) {
        setOcrData(_ocrData);
      }
    } else {
      _pdfUrl = await getRawFile(file, setDownloadFileProgress);
    }

    if (!_pdfUrl) {
      setLoadingState('error');
      return;
    }

    setLoadingState('success');
    setPdfUrl(_pdfUrl);

    // tracking
    clarityService.trackingClickedDocument(file);
  }, [file, getFile, getRawFile, tags]);

  useEffect(() => {
    if (file && file.uuid) {
      getPdfFile();
    }
  }, [file, getPdfFile]);

  const goHome = useCallback(() => {
    const pathname: string = PageUrl.RESULTS;
    const queryStringObj = queryString.parse(window.location.search);

    // remove unnecessary params
    delete queryStringObj['document_number'];
    delete queryStringObj['uuid'];
    delete queryStringObj['revision'];

    const _selectedProjectCodeObjects = project_codes.map(
      (project_code: TYPE_PROJECT_CODE) => {
        return PROJECT_CODE_OBJECTS[project_code];
      },
    );

    navigate({
      pathname: pathname,
      search: urlHelper.convertPageSearchQuery(
        tags,
        view,
        _selectedProjectCodeObjects,
        limit,
        offset,
      ),
    });
  }, [limit, navigate, offset, project_codes, tags, view]);

  const onHide = useCallback(() => {
    if (!file) {
      return;
    }

    goHome();
  }, [file, goHome]);

  if (!uuid) {
    goHome();
  }

  if (loadingState === 'loading') {
    return <ProgressBar className="w-100" now={downloadFileProgress} />;
  }

  if (!file || !pdfUrl) {
    return <div />;
  }

  if (!file) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <WarningIcon width={80} height={80} />
        <h3>{t('search_result_text_something_wrong')}</h3>
        <p>{t('search_result_text_no_results_try_again')}</p>
      </div>
    );
  }

  return (
    <PdfDetailStandalone
      file={file}
      pdfUrl={pdfUrl}
      positions={ocrData?.positions || []}
      loadingState={loadingState}
      onHide={onHide}
    />
  );
}
