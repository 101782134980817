import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PdfHighlight } from 'react-pdf-highlighter';
import { twMerge } from 'tailwind-merge';

import { colors } from '../../constants';
import { getPreviewFromQuerystring } from '../../helpers';
import { formatDateString } from '../../helpers/utils';
import { clarityService, DOWNLOAD_OPTION } from '../../services';
import { fileService } from '../../services/FileService';
import { FileInfoV2 } from '../../services/SearchService';
import { DefaultFileIcon, FileDownloadIcon, MenuIcon } from '../icons';
import { SearchTerm } from '../pdf/pdf';
import { HoverableTitle } from './HoverableTitle';

interface ThumbDetailsProps {
  file: FileInfoV2;
  tags: string[];
  onViewPdf?: (
    file: FileInfoV2,
    searchTerms: SearchTerm[],
    highlight?: PdfHighlight,
  ) => void;
  detailPageUrl?: string;
}

export const ThumbDetails = ({
  file,
  onViewPdf,
  tags,
  detailPageUrl,
}: ThumbDetailsProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [previewFeature] = useState<boolean>(() => {
    return getPreviewFromQuerystring();
  });

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        isMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(e.target as Node)
      ) {
        closeMenu();
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const coloredTerms: SearchTerm[] = useMemo(
    () =>
      tags.map((value, index, _) => {
        return { term: value, color: colors[index].rgba };
      }),
    [tags],
  );

  const previewFile = useCallback(() => {
    onViewPdf?.(file, coloredTerms);
  }, [onViewPdf, file, coloredTerms]);

  const saveFile = useCallback(async () => {
    if (!isLoadingFile) {
      setIsLoadingFile(true);
      try {
        await fileService.saveFile(file.uuid);
        clarityService.trackingDownloadHistory(DOWNLOAD_OPTION.single, 1, file);
        setTimeout(() => {
          setIsLoadingFile(false);
          closeMenu();
        }, 1000);
      } catch (error) {
        console.error(error);
        setIsLoadingFile(false);
        closeMenu();
        clarityService.trackingDownloadFailureHistory(
          DOWNLOAD_OPTION.single,
          1,
          file,
        );
      }
    }
  }, [file, isLoadingFile]);

  return (
    <div className="thumb-details flex-grow">
      <div className="thumb-details__header">
        <HoverableTitle
          title={file.title || t('document_preview_text_untitled')}
        />
        <div
          className={twMerge('thumb-details__menu', isMenuOpen && 'is-open')}
        >
          <MenuIcon onClick={() => setIsMenuOpen(!isMenuOpen)} />
          {isMenuOpen && (
            <div ref={menuRef}>
              <div onClick={previewFile}>
                <DefaultFileIcon role="button" width="22px" height="20px" />
                <span>{t('document_preview_text_preview')}</span>
              </div>
              {previewFeature && detailPageUrl && (
                <div onClick={closeMenu}>
                  <a
                    href={detailPageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white [&>svg]:mr-2"
                  >
                    <DefaultFileIcon role="button" width="22px" height="20px" />
                    <span>{t('document_preview_text_preview_in_new_tab')}</span>
                  </a>
                </div>
              )}
              <div onClick={saveFile}>
                {isLoadingFile ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    style={{ width: '16px', height: '16px', margin: '3px' }}
                  />
                ) : (
                  <FileDownloadIcon role="button" width="22px" height="20px" />
                )}
                <span>{t('document_preview_text_download')}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="thumb-details__content">
        <div>
          <strong>{t('document_preview_text_document_number')}</strong>
          <span title={file.document_number}>
            {file.document_number || '-'}
          </span>
        </div>
        <div className="thumb-details__revision">
          <div>
            <strong>{t('document_preview_text_revision')}</strong>
            <span>{file.revision}</span>
          </div>
          <div>
            <strong>{t('document_preview_text_revision_date')}</strong>
            <span>{formatDateString(file?.revision_date, i18n.language)}</span>
          </div>
          {/* To remove score field on production: https://dev.azure.com/MODEC-DnA/dna-mops-MODEC-Bing/_workitems/edit/6030 */}
          {/* <div>
						<strong>{t('document_preview_text_score')}</strong>
						<span>{file?.search_score}</span>
					</div> */}
        </div>
      </div>
    </div>
  );
};
