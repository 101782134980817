import { useTranslation } from 'react-i18next';

import { DefaultFileIcon } from '@/components/icons';

import { ReactComponent as HelpIcon } from '../assets/help.svg';

export default function NoResult() {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 m-auto">
      <DefaultFileIcon />
      <h3>{t('search_result_text_no_results_found')}</h3>
      <p>{t('search_result_text_no_results_try_again')}</p>

      <p className="mb-0 mt-4">
        Please visit our{' '}
        <a
          href="https://modec.sharepoint.com/teams/MOPSDnA/SitePages/MODEC-Bing-v2.aspx"
          target="_blank"
          rel="noreferrer"
          className="text-[#3F5BFF] hover:text-white"
        >
          product page
        </a>{' '}
        and watch the tutorial video about best practices for searching on MODEC
        Bing.
      </p>
      <p className="flex items-center">
        You can also click on the help icon&#160;
        <HelpIcon />
        &#160;at the top right corner to send us a message.
      </p>
    </div>
  );
}
