import { useAccount } from '@azure/msal-react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';

import { ReactComponent as UploadIcon } from './assets/upload.svg';
import { helpFormMutationAtom, helpFormOpenAtom } from './atom';

type HelpFormData = {
  keyword_used: string;
  targeted_document: string;
  comment: string;
};

type HelpFormProps = {
  selectedOption: 'Document' | 'Other';
};

export default function HelpForm({ selectedOption }: HelpFormProps) {
  const account = useAccount();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isSubmitting, isValid },
  } = useForm<HelpFormData>({
    mode: 'onChange',
  });
  const helpFormMutationStatus = useAtomValue(helpFormMutationAtom);

  const [screenshots, setScreenshots] = useState<File[]>([]);

  const onDrop = useCallback((files) => {
    // Only allow to upload 1 file
    setScreenshots(() => [...files]);
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const { getRootProps, getInputProps } = useDropzone({
    // accept: {
    //   'image/jpeg': ['.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp'],
    //   'image/png': ['.png'],
    //   'video/quicktime': ['mov'],
    // },
    multiple: false,
    minSize: 1,
    maxSize: 20 * 1024 * 1024,
    onDrop,
    onDropRejected() {
      enqueueSnackbar('File size should be less than 20MB', {
        variant: 'error',
      });
    },
  });

  useEffect(() => {
    if (selectedOption) {
      reset();
      setScreenshots([]);
    }
  }, [reset, selectedOption]);

  const setOpen = useSetAtom(helpFormOpenAtom);

  return (
    <form
      onSubmit={handleSubmit((data) =>
        helpFormMutationStatus
          .mutateAsync({
            ...data,
            mail: account?.username ?? '',
            issue: selectedOption,
            screenshots,
          })
          .then((res) => {
            if (res.ok) {
              enqueueSnackbar('Support ticket submitted!', {
                variant: 'success',
              });
              setOpen(false);
            } else
              enqueueSnackbar(
                'Support under maintenance. Please submit a ticket later.',
                {
                  variant: 'error',
                },
              );
          }),
      )}
    >
      <div className="!mt-4 flex flex-col">
        <div className="space-y-4">
          {selectedOption === 'Document' && (
            <>
              <div className="flex flex-col gap-[2px]">
                <label className="mb-[2px]">Keyword used</label>
                <input
                  {...register('keyword_used', {
                    required:
                      selectedOption === 'Document' &&
                      'Please enter the keyword used.',
                  })}
                  className="rounded-lg !px-4 py-2 text-black"
                  placeholder="Eg. 90DP, Nitrogen"
                />
                <div className="text-[#FF0000]">
                  {errors.keyword_used?.message}
                </div>
              </div>
              <div className="flex flex-col gap-[2px]">
                <label className="mb-[2px]">Targeted document</label>
                <textarea
                  {...register('targeted_document', {
                    required:
                      selectedOption === 'Document' &&
                      'Please enter the targeted search document',
                  })}
                  className="rounded-lg !px-4 py-2 text-black"
                  placeholder="Eg. 4103-MI20-90DP-3711-001, Uaru Nitrogen Receiver P&ID"
                />
                <div className="text-[#FF0000]">
                  {errors.targeted_document?.message}
                </div>
              </div>
            </>
          )}
          <div className="flex flex-col">
            <label className="mb-[2px]">
              {selectedOption === 'Document'
                ? 'Additional comment'
                : 'Let us know more'}
            </label>
            <textarea
              {...register('comment', {
                required:
                  selectedOption === 'Other' &&
                  'Please enter the issue you are having.',
              })}
              rows={4}
              className="rounded-lg !px-4 py-2 text-black"
              placeholder="Describe your issue"
            />
            <div className="text-[#FF0000]">{errors.comment?.message}</div>
          </div>
        </div>

        <div
          {...getRootProps()}
          className="!mt-6 flex cursor-pointer items-center gap-7 rounded-lg border !border-dashed border-[#CCCCCC] !px-6 !py-5"
        >
          <input {...getInputProps()} />

          <div className="h-9 w-7">
            <UploadIcon />
          </div>

          {screenshots.length ? (
            screenshots.map((file) => (
              <div
                key={file.name}
                className="flex cursor-default flex-col gap-y-[2px] overflow-hidden"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <div className="truncate">File: {file.name}</div>
                <button
                  className="w-fit cursor-pointer text-[#F00]"
                  onClick={() => {
                    setScreenshots((prevState) =>
                      prevState.filter(
                        (screenshot) => screenshot.name !== file.name,
                      ),
                    );
                  }}
                >
                  Remove
                </button>
              </div>
            ))
          ) : (
            <span className="leading-tight">
              Click or drag-and-drop your screenshot, video, or document to
              clarify your issue.
            </span>
          )}
        </div>
        <button
          type="submit"
          className="!mt-4 h-10 self-end rounded-lg bg-[#3A4BB3] px-9 text-white disabled:cursor-not-allowed"
          disabled={!isDirty || !isValid || isSubmitting}
        >
          Submit
        </button>
      </div>
    </form>
  );
}
