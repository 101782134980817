import { ReactElement } from 'react';

import { ReactComponent as Zip } from '../../assets/img/compressed.svg';
import { ReactComponent as Excel } from '../../assets/img/excel.svg';
import { ReactComponent as Image } from '../../assets/img/image.svg';
import { ReactComponent as Pdf } from '../../assets/img/pdf.svg';
import { ReactComponent as Pptx } from '../../assets/img/pptx.svg';
import { ReactComponent as Text } from '../../assets/img/text.svg';
import { ReactComponent as Video } from '../../assets/img/video-playlist.svg';
import { ReactComponent as Word } from '../../assets/img/word.svg';

const iconProps = {
  width: 40,
  height: 40,
};

const iconByExt = {
  doc: <Word {...iconProps} />,
  docm: <Word {...iconProps} />,
  docx: <Word {...iconProps} />,
  odt: <Word {...iconProps} />,
  xls: <Excel {...iconProps} />,
  xlsm: <Excel {...iconProps} />,
  xlsx: <Excel {...iconProps} />,
  ods: <Excel {...iconProps} />,
  ppt: <Pptx {...iconProps} />,
  pptm: <Pptx {...iconProps} />,
  pptx: <Pptx {...iconProps} />,
  odp: <Pptx {...iconProps} />,
  pdf: <Pdf {...iconProps} />,
  png: <Image {...iconProps} />,
  jpg: <Image {...iconProps} />,
  jpeg: <Image {...iconProps} />,
  txt: <Text {...iconProps} />,
  csv: <Text {...iconProps} />,
  rtf: <Text {...iconProps} />,
  json: <Text {...iconProps} />,
  html: <Text {...iconProps} />,
  xml: <Text {...iconProps} />,
  mpg: <Video {...iconProps} />,
  mpeg: <Video {...iconProps} />,
  mp4: <Video {...iconProps} />,
  epub: <Zip {...iconProps} />,
  zip: <Zip {...iconProps} />,
  gz: <Zip {...iconProps} />,
  eml: <Zip {...iconProps} />,
};

export type FileType = keyof typeof iconByExt;

interface FileIconProps {
  type: FileType;
}

export const FileIcon = ({ type }: FileIconProps): ReactElement => {
  return <div>{iconByExt[type]}</div>;
};
