import { isUndefined, throttle } from 'lodash';
import {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PdfHighlight } from 'react-pdf-highlighter';
import { twMerge } from 'tailwind-merge';

import { colors, FitView } from '../../constants';
import { trimSearchTermForPdf } from '../../helpers/utils';
import { useSearchQuery } from '../../hooks';
import { TypeLoadingState } from '../../pages/Detail';
import { PdfOcrData } from '../../services/FileService';
import { FileInfoV2 } from '../../services/SearchService';
import { DetailHeaderStandalone } from '../commons';
import { BrokenImageIcon } from '../icons';
import { SearchTerm } from './pdf';
import { PdfViewer } from './PdfViewer';
import { PdfViewerUtility } from './PdfViewer.interface';

export interface PdfDetailStandaloneProps {
  file: FileInfoV2;
  pdfUrl: string;
  positions: PdfOcrData['positions'];
  loadingState: TypeLoadingState;
  onHide: () => void;
}

export function PdfDetailStandalone(
  props: PdfDetailStandaloneProps,
): ReactElement {
  const { file, loadingState, pdfUrl, positions } = props;

  const { tags } = useSearchQuery();

  const searchTerms: SearchTerm[] = useMemo(
    () =>
      tags.map((value, index, _) => {
        return { term: value, color: colors[index].rgba };
      }),
    [tags],
  );
  const { t } = useTranslation();
  const [pdfUtil, setPdfUtil] = useState<PdfViewerUtility>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [selectedSearchTerms, setSelectedSearchTerms] = useState<
    Record<string, boolean>
  >({});
  const [matchedSearchTerms, setMatchedSearchTerms] = useState<
    Record<string, number>
  >({});
  const [orderedMatchedSearchTerms, setOrderedMatchedSearchTerms] = useState<
    SearchTerm[]
  >([]);
  const [currentFindTermIndex, setCurrentFindTermIndex] = useState<
    Record<string, number>
  >({});
  const [scale, setScale] = useState<number>();

  const onScaleChange = useCallback((value: number) => {
    setScale(Math.round(value * 100));
  }, []);

  const setPdfViewerUtility = useCallback((pdfUtil: PdfViewerUtility) => {
    setPdfUtil(pdfUtil);
  }, []);

  useEffect(() => {
    const _matchedSearchTermsLocal = pdfUtil?.getMatchSearchTerms();
    if (_matchedSearchTermsLocal) {
      setMatchedSearchTerms(_matchedSearchTermsLocal);
      const orderedMatchedTerms = searchTerms?.filter((item: SearchTerm) => {
        return _matchedSearchTermsLocal[trimSearchTermForPdf(item.term)] > 0;
      });
      setOrderedMatchedSearchTerms(orderedMatchedTerms);

      const defaultSelectedSearchTerms = orderedMatchedTerms.reduce(
        (acc: Record<string, boolean>, item: SearchTerm) => ({
          ...acc,
          [trimSearchTermForPdf(item.term)]: true,
        }),
        {},
      );
      setSelectedSearchTerms(defaultSelectedSearchTerms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfUtil]);

  const toggleKeyword =
    (keyword: string): MouseEventHandler =>
    () => {
      pdfUtil?.toggleHighlight(keyword, !selectedSearchTerms[keyword]);
      setSelectedSearchTerms((prevState: Record<string, boolean>) => ({
        ...prevState,
        [keyword]: !prevState[keyword],
      }));
    };

  const handleNextHighlight =
    (searchTerm: string): MouseEventHandler =>
    () => {
      pdfUtil?.nextHighlight(searchTerm);
    };

  const handlePreviousHighlight =
    (searchTerm: string): MouseEventHandler =>
    () => {
      pdfUtil?.prevHighlight(searchTerm);
    };

  const onFocusHighlightChange = useCallback((highlight?: PdfHighlight) => {
    setIsProcessing(false);
    highlight &&
      setCurrentFindTermIndex(() => {
        return {
          [highlight.content.text]: highlight.position.indexInFile + 1,
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFitViewerCallback = useCallback(
    (type: string) => {
      switch (type) {
        case FitView.ORIGIN:
          pdfUtil?.viewer.resetZoom();
          break;
        case FitView.HEIGHT:
          pdfUtil?.viewer.fitHeight();
          break;
        default:
          pdfUtil?.viewer.fitWidth();
      }
    },
    [pdfUtil],
  );

  const onZoomChange = useCallback(
    throttle((scaleValue: number) => {
      !isUndefined(scaleValue) &&
        setTimeout(() => pdfUtil?.viewer.zoom(scaleValue / 100), 0);
    }, 100), // thorttle scaling 100ms for avoid impact rendering performance
    [pdfUtil],
  );

  return (
    <div
      className="flex w-full flex-col"
      style={{
        flex: 1,
      }}
    >
      <DetailHeaderStandalone
        file={file}
        title={file?.title || t('document_preview_text_untitled')}
        scale={scale}
        pdfOrderedMatchedSearchTerms={orderedMatchedSearchTerms}
        pdfMatchedSearchTerms={matchedSearchTerms}
        selectedKeywords={selectedSearchTerms}
        findKeywordIndex={currentFindTermIndex}
        disabled={loadingState === 'loading'}
        onHide={props.onHide}
        toggleKeyword={toggleKeyword}
        handleNextHighlight={handleNextHighlight}
        handlePreviousHighlight={handlePreviousHighlight}
        handleFitViewerCallback={handleFitViewerCallback}
        onScalePercentageChange={onZoomChange}
      />

      <div
        style={{
          padding: '1rem',
          height: 'calc(100vh - 100px)',
        }}
      >
        {loadingState === 'error' ? (
          <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
            <BrokenImageIcon />
            <div>{t('document_preview_text_loading_failed')}</div>
          </div>
        ) : (
          <>
            {loadingState === 'loading' && (
              <Spinner
                animation="border"
                className="position-absolute"
                style={{ top: '50%', left: '50%' }}
              />
            )}
            {loadingState === 'success' && pdfUrl && (
              <div
                className={twMerge(
                  'd-inline',
                  isProcessing ? 'is-processing' : '',
                )}
              >
                <PdfViewer
                  url={pdfUrl}
                  pdfViewMode="detail"
                  searchTerms={searchTerms}
                  positions={positions}
                  highlightToScroll={undefined}
                  scrollable
                  pdfScale="page-actual"
                  autoScale={false}
                  onFocusHighlightChange={onFocusHighlightChange}
                  setPdfViewerUtility={setPdfViewerUtility}
                  onScaleChange={onScaleChange}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
