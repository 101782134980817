import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  DEFAULT_CURRENT_PAGE,
  IPagination,
  LEFT_PAGE,
  PAGE_LIMIT,
  PAGE_NEIGHBOURS,
  range,
  RIGHT_PAGE,
  TOTAL_PAGES,
  TOTAL_RECORDS,
} from '../helpers';

const PaginationContext = createContext({
  pageNumbers: [] as any[],
  currentPage: DEFAULT_CURRENT_PAGE,
  pageLimit: PAGE_LIMIT,
  handleClick: (page: number, event: any, url?: string) => {},
  handleMoveLeft: (event: any) => {},
  handleMoveRight: (event: any) => {},
});

export const PaginationProvider = (props: IPagination): any => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [pageLimit, setPageLimit] = useState(props.pageLimit || 10);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const offset = searchParams.get('offset');

  const [totalRecords, setTotalRecords] = useState(TOTAL_RECORDS);
  const [pageNeighbours] = useState(PAGE_NEIGHBOURS);
  const [totalPages, setTotalPages] = useState<number>(TOTAL_PAGES);
  const [currentPage, setCurrentPage] = useState<number>(
    Math.ceil(Number(offset || 0) / pageLimit + 1),
  );

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      const newTotalPages = Math.ceil(totalRecords / pageLimit);
      setTotalPages(newTotalPages);
    }
  }, [initialized, pageLimit, totalRecords]);

  useEffect(() => {
    setCurrentPage(Math.ceil(Number(offset || 0) / pageLimit + 1));
  }, [pageLimit, offset]);

  useEffect(() => {
    setPageLimit(
      typeof props.pageLimit === 'number' ? props.pageLimit : PAGE_LIMIT,
    );

    setTotalRecords(
      typeof props.totalRecords === 'number'
        ? props.totalRecords
        : TOTAL_RECORDS,
    );
  }, [props.totalRecords, props.pageLimit]);

  useEffect(() => {
    const newTotalPages = Math.ceil(totalRecords / pageLimit);

    setTotalPages(newTotalPages);
  }, [pageLimit, totalRecords]);

  const gotoPage = (page = 1, url = '') => {
    const newCurrentPage: number = Math.max(0, Math.min(page, totalPages));

    setCurrentPage(newCurrentPage);

    if (url) {
      navigate(url);
    } else {
      if (!isEmpty(searchParams)) {
        navigate(
          `${window.location.origin}${
            window.location.pathname
          }?${searchParams.toString()}`,
        );
      }
    }
  };

  const handleClick = (page: number, event: any, url?: string) => {
    event.preventDefault();
    gotoPage(page, url);
  };

  const handleMoveLeft = (event: any) => {
    event.preventDefault();
    gotoPage(currentPage - pageNeighbours * 2 - 1);
  };

  const handleMoveRight = (event: any) => {
    event.preventDefault();
    gotoPage(currentPage + pageNeighbours * 2 + 1);
  };

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages: (number | string)[] = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  const pageNumbers = fetchPageNumbers();

  return (
    <PaginationContext.Provider
      value={{
        pageLimit,
        currentPage,
        pageNumbers,
        handleClick,
        handleMoveLeft,
        handleMoveRight,
      }}
    >
      {props.children}
    </PaginationContext.Provider>
  );
};

export const usePagination = () => useContext(PaginationContext);
