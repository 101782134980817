import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const MIN_HEIGHT_TITLE = 48;
export const HoverableTitle = ({ title }: { title: string }): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showEllipsis, setShowEllipsis] = useState(false);

  useEffect(() => {
    if (
      containerRef.current &&
      containerRef.current.scrollHeight > MIN_HEIGHT_TITLE
    ) {
      setShowEllipsis(true);
    }
  }, []);

  if (showEllipsis) {
    return (
      <OverlayTrigger
        transition={false}
        placement="bottom"
        delay={{ show: 150, hide: 200 }}
        overlay={<Tooltip id="title-tooltip">{title}</Tooltip>}
      >
        <div className="title">
          <p className="mb-0 line-clamp-2">{title}</p>
        </div>
      </OverlayTrigger>
    );
  }

  return (
    <div className="title" ref={containerRef}>
      <p className="mb-0">{title}</p>
    </div>
  );
};
