import { ApiUrl } from '../constants';
import { httpClient, HttpClientParameters } from './HttpClient';

class AuthService {
  preLogout() {
    const parameters: HttpClientParameters = {
      url: ApiUrl.PRE_LOGOUT,
      requiresToken: true,
    };
    return httpClient.post(parameters);
  }

  logout(payload: any) {
    const parameters: HttpClientParameters = {
      url: ApiUrl.LOGOUT,
      requiresToken: false,
      payload: payload,
    };
    return httpClient.post(parameters);
  }
}

export const authService = new AuthService();
