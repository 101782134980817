import { CircularProgress } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { Fragment, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  documentDetailAtom,
  documentDetailIdAtom,
  documentDetailTagsAtom,
} from '@/atoms/documentDetail';
import { helpFormOpenAtom } from '@/components/layouts/Header/Help/atom';
import { PdfDetailStandalone } from '@/components/pdf/PdfDetailStandalone';

export default function DocumentDetail() {
  const params = useParams<{
    documentNumber: string;
  }>();
  const [searchParams] = useSearchParams();
  const tags = searchParams.get('tags') as string[] | null;

  const setHelpFormOpen = useSetAtom(helpFormOpenAtom);
  const setDocumentNumber = useSetAtom(documentDetailIdAtom);
  const setDocumentTags = useSetAtom(documentDetailTagsAtom);
  const documentDetail = useAtomValue(documentDetailAtom);
  const { data, isError, isLoading } = documentDetail;

  useEffect(() => {
    setDocumentNumber(params.documentNumber || '');
  }, [params.documentNumber, setDocumentNumber]);

  useEffect(() => {
    setDocumentTags(tags ?? []);
  }, [tags, setDocumentTags]);

  const loadingState = useMemo(() => {
    if (isError) {
      return 'error';
    }

    return isLoading ? 'loading' : 'success';
  }, [isError, isLoading]);

  if (isLoading) {
    return (
      <div className="grid w-full place-content-center">
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex w-full flex-col items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="40"
          viewBox="0 0 32 40"
          fill="none"
        >
          <path
            d="M10 30H22M10 24H22M18.0008 2.00174C17.8097 2 17.5948 2 17.3494 2H8.40039C6.16018 2 5.03924 2 4.18359 2.43598C3.43095 2.81947 2.81947 3.43095 2.43597 4.18359C2 5.03924 2 6.16018 2 8.40039V31.6004C2 33.8406 2 34.9601 2.43598 35.8158C2.81947 36.5684 3.43095 37.181 4.18359 37.5645C5.03841 38 6.15801 38 8.39388 38L23.6062 38C25.842 38 26.96 38 27.8148 37.5645C28.5674 37.181 29.181 36.5684 29.5645 35.8158C30 34.961 30 33.843 30 31.6072V14.6514C30 14.406 29.9998 14.1911 29.9981 14M18.0008 2.00174C18.5717 2.00695 18.9314 2.02813 19.2764 2.11094C19.6845 2.20893 20.0757 2.37053 20.4336 2.58984C20.8371 2.83713 21.1836 3.18363 21.875 3.875L28.126 10.126C28.8178 10.8178 29.1617 11.1627 29.4091 11.5664C29.6284 11.9243 29.7907 12.3145 29.8887 12.7227C29.9715 13.0676 29.9928 13.4291 29.9981 14M18.0008 2.00174L18 7.60042C18 9.84063 18 10.9603 18.436 11.8159C18.8195 12.5686 19.4309 13.181 20.1836 13.5645C21.0384 14 22.158 14 24.3938 14H29.9981"
            stroke="#FAFAFA"
            strokeWidth="2.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="mt-2 text-[32px] font-bold">
          Document does not exist
        </div>
        <div className="mt-4 text-center">
          The document that you have requested for may not be available within
          MODEC Bing.
        </div>
        <div className="mt-2">
          Please verify the document number or try another.
        </div>

        <div className="mt-6">
          For additional help, visit our{' '}
          <a
            href="https://modec.sharepoint.com/teams/MOPSDnA/SitePages/MODEC-Bing-v2.aspx"
            target="_blank"
            rel="noreferrer"
            className="text-[#8C9DFF] hover:text-white"
          >
            product page
          </a>{' '}
          or{' '}
          <span
            className="cursor-pointer text-[#8C9DFF] hover:text-white"
            onClick={() => {
              setHelpFormOpen(true);
            }}
          >
            send us a message.
          </span>
        </div>
      </div>
    );
  }

  if (!data) return <Fragment />;

  return (
    <PdfDetailStandalone
      file={data.file}
      pdfUrl={data.fileUrl ?? ''}
      positions={data?.ocrData?.positions ?? []}
      loadingState={loadingState}
      onHide={() => {}}
    />
  );
}
