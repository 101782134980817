import { clarity } from 'clarity-js';

import { PageUrlParam } from '../constants';
import { formatDate, getCookie, getQueryParams, setCookie } from '../helpers';
import { FileInfoV2 } from './SearchService';

export enum DOWNLOAD_OPTION {
  single = 'single',
  multiple = 'multiple',
  multiple_as_zip = 'multiple_as_zip',
}

export type DOWNLOAD_TYPE =
  | DOWNLOAD_OPTION.single
  | DOWNLOAD_OPTION.multiple
  | DOWNLOAD_OPTION.multiple_as_zip;

class ClarityService {
  _clarity = clarity;
  _enable = true;

  init = (name: string, username: string) => {
    if (this._enable === false || !username) {
      return;
    }

    this._clarity.consent();
    setCookie('bing_user_email', username, 1);
    setCookie('bing_user_name', name, 1);
    this.set('bing_user_email', username);
    this.set('bing_user_name', name);

    this.start(process.env.REACT_APP_MS_CLARITY_ID ?? `hgi483dr30`);
  };

  start = (id: string): void => {
    if (!id) {
      return;
    }

    this._clarity.start({
      projectId: id,
      upload: 'https://m.clarity.ms/collect',
      track: true,
      content: true,
      cookies: ['bing_user_email', 'bing_user_name'],
    });

    this._clarity.identify(
      getCookie('bing_user_email') ?? '',
      formatDate(new Date()),
      window.location.pathname,
    );
  };

  set = (key: string, value: string) => {
    this._clarity.set(key, value);
  };

  stop = (): void => {
    this._clarity.stop();
  };

  trackingDownloadHistory = (
    downloadType: DOWNLOAD_TYPE,
    numberOfFiles?: number,
    file?: FileInfoV2,
  ): void => {
    if (this._enable === false) {
      return;
    }
    const _clarityObj = this._prepareClarityData(file);
    let downloadHistoryValue = `${_clarityObj.now}|${_clarityObj.userEmail}|${_clarityObj.queries}|${_clarityObj.projectCodes}`;
    downloadHistoryValue = `${downloadHistoryValue}|${
      _clarityObj.documentNumber
    }|${_clarityObj.view}|download:${
      DOWNLOAD_OPTION[downloadType as keyof typeof DOWNLOAD_OPTION]
    }:number_of_files:${numberOfFiles}`;

    this._clarity.set('download_history', downloadHistoryValue);

    const userHint = `download:${_clarityObj.queries}|${_clarityObj.projectCodes}|${_clarityObj.documentNumber}|${_clarityObj.view}|number_of_files:${numberOfFiles}`;
    this.trackingIdentifyWithLocation(userHint);
  };

  trackingDownloadFailureHistory = (
    downloadType: DOWNLOAD_TYPE,
    numberOfFiles?: number,
    file?: FileInfoV2,
  ): void => {
    if (this._enable === false) {
      return;
    }
    const _clarityObj = this._prepareClarityData(file);
    let downloadHistoryValue = `${_clarityObj.now}|${_clarityObj.userEmail}|${_clarityObj.queries}|${_clarityObj.projectCodes}`;
    downloadHistoryValue = `${downloadHistoryValue}|${
      _clarityObj.documentNumber
    }|${_clarityObj.view}|download:${
      DOWNLOAD_OPTION[downloadType as keyof typeof DOWNLOAD_OPTION]
    }:number_of_files:${numberOfFiles}`;

    this._clarity.set('download_history', downloadHistoryValue);
  };

  trackingSearchHistory = (): void => {
    if (this._enable === false) {
      return;
    }
    const _clarityObj = this._prepareClarityData();
    let searchHistoryValue = `${_clarityObj.now}|${_clarityObj.userEmail}|${_clarityObj.queries}|${_clarityObj.projectCodes}`;
    searchHistoryValue = `${searchHistoryValue}|${_clarityObj.documentNumber}|${_clarityObj.view}`;

    this._clarity.set('search_history', searchHistoryValue);

    const userHint = `search_history:${_clarityObj.queries}|${_clarityObj.projectCodes}|${_clarityObj.documentNumber}|${_clarityObj.view}`;
    this.trackingIdentifyWithLocation(userHint);
  };

  trackingClickedDocument = (file: FileInfoV2): void => {
    if (this._enable === false) {
      return;
    }
    const _clarityObj = this._prepareClarityData(file);
    let clickedDocumentValue = `${_clarityObj.now}|${_clarityObj.userEmail}|${_clarityObj.queries}|${_clarityObj.projectCodes}`;
    clickedDocumentValue = `${clickedDocumentValue}|${_clarityObj.documentNumber}|${_clarityObj.view}`;

    this._clarity.set('clicked_document', clickedDocumentValue);

    const userHint = `clicked_document:${_clarityObj.queries}|${_clarityObj.projectCodes}|${_clarityObj.documentNumber}|${_clarityObj.view}`;
    this.trackingIdentifyWithLocation(userHint);
  };

  trackingIdentifyWithLocation = (userHint?: string): void => {
    if (this._enable === false) {
      return;
    }

    const pathname = window.location.pathname;
    const { userEmail, now } = this._prepareClarityData();
    if (userHint) {
      this._clarity.identify(userEmail, now, pathname, userHint);
    } else {
      this._clarity.identify(userEmail, now, pathname);
    }
  };

  private _prepareClarityData = (
    file?: FileInfoV2,
  ): {
    userEmail: string;
    now: string;
    queries: string;
    projectCodes: string;
    view: string;
    documentNumber: string;
  } => {
    const params = getQueryParams();
    const userEmail = getCookie('bing_user_email') ?? '';
    const now = formatDate(new Date());
    const queries = params[PageUrlParam.QUERY] ?? [];
    const projectCodes = params[PageUrlParam.PROJECT_CODES] ?? '';
    const view = params[PageUrlParam.VIEW_OPTION] ?? '';
    const documentNumber = file?.document_number ?? '';

    return {
      userEmail,
      now,
      queries,
      projectCodes,
      view,
      documentNumber,
    };
  };
}

export const clarityService = new ClarityService();
