import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import Detail from '@/pages/Detail';
import Home from '@/pages/Home';
import Logout from '@/pages/Logout';
import Results from '@/pages/Results';

import App from './App';
import { PageUrl } from './constants';
import DocumentDetail from './pages/DocumentDetail';

export const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: PageUrl.LOGOUT,
        element: <Logout />,
      },
      {
        path: PageUrl.HOME,
        element: <Home />,
      },
      {
        element: (
          <>
            <AuthenticatedTemplate>
              <Outlet />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <Navigate to={PageUrl.HOME} />
            </UnauthenticatedTemplate>
          </>
        ),
        children: [
          {
            path: '/doc/:documentNumber',
            element: <DocumentDetail />,
          },
          {
            path: PageUrl.RESULTS,
            element: <Results />,
          },
          {
            path: PageUrl.DETAIL,
            element: <Detail />,
          },
        ],
      },
    ],
  },
]);
