import { useEffect, useState } from 'react';
import { PdfHighlight } from 'react-pdf-highlighter';

import { isPunctuation, splitWordByPunctuation } from '../../helpers/utils';
import { OcrPage } from '../../services/FileService';
import {
  addHighlight,
  CustomizedPdfHighlighter,
  getNextId,
  normalizeRectScale,
  SearchTerm,
} from './pdf';

interface WordPosition {
  pageNumber: number;
  pageWidth: number;
  pageHeight: number;
  text: string;
  boundingBox: number[];
  line: number;
}

interface RectVertex {
  x: number;
  y: number;
}

// said Quan: This rotation logic is a critical part of OCR highlight
function rotate(x: number, y: number, w: number, h: number, rotation?: number) {
  const point = {
    x: (y / w) * h,
    y: (x / h) * w,
  };

  switch (rotation) {
    // rotate 90 degree
    case 90:
      return [h - point.x, point.y];
    // rotate 180 degree
    case 180:
      return [w - point.x, h - point.y];
    // rotate 270 degree
    case 270:
      return [point.x, w - point.y];
    // 0 degree
    default:
      return [point.x, point.y];
  }
}

const rotateWord = (word: WordPosition, rotation = 0) => {
  const { pageWidth, pageHeight, boundingBox } = word;
  const [x1, y1, x2, y2, x3, y3, x4, y4] = boundingBox;

  word.pageWidth = pageHeight;
  word.pageHeight = pageWidth;
  word.boundingBox = [
    ...rotate(x1, y1, pageWidth, pageHeight, rotation),
    ...rotate(x2, y2, pageWidth, pageHeight, rotation),
    ...rotate(x3, y3, pageWidth, pageHeight, rotation),
    ...rotate(x4, y4, pageWidth, pageHeight, rotation),
  ];
};

const calculateHighlightRect = (
  boundingBox: number[],
  pageWidth: number,
  pageHeight: number,
  lineNo: number,
) => {
  const vertices = getClockwiseVerticesFromTopLeft(boundingBox);
  const center = {
    x:
      Math.min(vertices[0].x, vertices[2].x) +
      Math.abs(vertices[0].x - vertices[2].x) / 2,
    y:
      Math.min(vertices[0].y, vertices[2].y) +
      Math.abs(vertices[0].y - vertices[2].y) / 2,
  };
  const width = Math.hypot(
    vertices[1].x - vertices[0].x,
    vertices[1].y - vertices[0].y,
  );
  const height = Math.hypot(
    vertices[3].x - vertices[0].x,
    vertices[3].y - vertices[0].y,
  );
  const angle =
    (Math.atan2(vertices[1].y - vertices[0].y, vertices[1].x - vertices[0].x) *
      180) /
    Math.PI;

  return {
    x1: center.x - width / 2, // top-left x
    y1: center.y - height / 2, // top-left y
    x2: center.x + width / 2, // bottom-right x
    y2: center.y + height / 2, // bottom-right y
    line: lineNo,
    width: pageWidth, // page width
    height: pageHeight, // page height
    boundingBox: boundingBox,
    angle,
  };
};

const getClockwiseVerticesFromTopLeft = (
  boundingBox: number[],
): RectVertex[] => {
  const vertices = [
    { index: 0, x: boundingBox[0], y: boundingBox[1] },
    { index: 1, x: boundingBox[2], y: boundingBox[3] },
    { index: 2, x: boundingBox[4], y: boundingBox[5] },
    { index: 3, x: boundingBox[6], y: boundingBox[7] },
  ];
  const sortedPointsByY = [...vertices].sort((p1, p2) => p1.y - p2.y);
  const topLeft =
    sortedPointsByY[0].x < sortedPointsByY[1].x
      ? sortedPointsByY[0]
      : sortedPointsByY[1];
  const topLeftIndex = vertices.findIndex(
    (p) => p.x === topLeft.x && p.y === topLeft.y,
  );
  return vertices.sort((p1, p2) => {
    const p1Index = p1.index + (p1.index < topLeftIndex ? vertices.length : 0);
    const p2Index = p2.index + (p2.index < topLeftIndex ? vertices.length : 0);
    return p1Index - p2Index;
  });
};

export const useOcrHighlight = (
  pdfHighlighter: CustomizedPdfHighlighter | undefined,
  searchTerms: SearchTerm[],
  positions?: OcrPage[],
): PdfHighlight[] => {
  const [highlights, setHighlights] = useState<PdfHighlight[]>([]);
  const [viewer, setViewer] = useState<HTMLDivElement>();

  useEffect(() => {
    if (!pdfHighlighter) return;

    pdfHighlighter.eventBus.on('pagesloaded', () => {
      setViewer(pdfHighlighter.viewer.viewer);
    });
  }, [pdfHighlighter]);

  useEffect(() => {
    if (!viewer || !positions || !pdfHighlighter) {
      return;
    }

    // said Quan: Since our API returns unrelated words in the same line, we need to filter out the unrelated words
    // keywords: 90DP
    // API response:
    // [
    // 	[
    // 		{
    // 			"bb": [],
    // 			"t": "(SUPERSEDED"
    // 		},
    // 		{
    // 			"bb": [],
    // 			"t": "BY"
    // 		},
    // 		{
    // 			"bb": [],
    // 			"t": "0468-SID20-90DP-5232-001)"
    // 		}
    // 	],
    // 	[
    // 		{
    // 			"bb": [],
    // 			"t": "0468-SID20-90DP-1003-001"
    // 		}
    // 	],
    // 	[
    // 		{
    // 			"bb": [],
    // 			"t": "0468-SID20-90DP-1003-001"
    // 		}
    // 	]
    // ]
    // Need to remove those text like: (SUPERSEDED, BY
    const wordPositions: WordPosition[] = [];
    positions.forEach(
      ({
        p: pageNumber,
        w: pageWidth,
        h: pageHeight,
        images,
        rotation = 0,
      }) => {
        // said Quan: special logic for rotated pages
        const rotated = rotation > 0;

        images.forEach(({ lines }) => {
          lines.forEach((line, lineNo) => {
            line.forEach(({ t: text, bb: boundingBox }) => {
              const contentWords = splitWordByPunctuation(
                text.toLowerCase(),
              ).filter((w) => !isPunctuation(w));
              contentWords.forEach((text) => {
                const wordPosition: WordPosition = {
                  pageNumber,
                  pageWidth,
                  pageHeight,
                  text,
                  boundingBox,
                  line: lineNo,
                };
                rotated && rotateWord(wordPosition, rotation);
                wordPositions.push(wordPosition);
              });
            });
          });
        });
      },
    );

    let countMatchedTerm: Record<string, number> = {};
    const _ocrHighlights: PdfHighlight[] = [];
    searchTerms.forEach(({ term, color }) => {
      const words = splitWordByPunctuation(term)
        .filter((w) => !isPunctuation(w))
        .map((w) => w.toLowerCase());

      let ocrWordIndex = 0;

      while (ocrWordIndex < wordPositions.length) {
        let found = false;
        let highlight: PdfHighlight | null = null;
        let lastMatchWord: any = null;

        for (let wordIndex = 0; wordIndex < words.length; wordIndex++) {
          const ocrWord = wordPositions[ocrWordIndex + wordIndex];
          const ocrWordContent = ocrWord?.text.toLowerCase() || '';

          // said Quan: This check cover cases like: certificate and certificates
          if (!ocrWordContent.includes(words[wordIndex])) {
            highlight = null;
            break;
          }

          const {
            pageNumber,
            pageWidth,
            pageHeight,
            boundingBox,
            line: ocrWordLine,
          } = ocrWord;

          if (!highlight || !lastMatchWord) {
            const rect = calculateHighlightRect(
              boundingBox,
              pageWidth,
              pageHeight,
              ocrWordLine,
            );
            countMatchedTerm[term] = (countMatchedTerm[term] || 0) + 1;
            highlight = {
              id: getNextId(),
              content: {
                text: term, // said Quan: "term" helps us to map keyword search among highlight points
              },
              position: {
                boundingRect: {
                  ...rect,
                },
                scaledBoundingRect: normalizeRectScale(
                  pageNumber,
                  rect,
                  pdfHighlighter.viewer,
                ),
                rects: [
                  {
                    ...rect,
                  },
                ],
                pageNumber,
                // said Quan: this logic will merge results of "certificates" into "certificate"
                indexInFile: countMatchedTerm[term]
                  ? countMatchedTerm[term] - 1
                  : -1,
                // temp index, will be calculated when merge with text highlight
                indexInPage: -1,
              },
              background: color,
              rotation: rect.angle,
              type: 'ocr',
            };
          } else {
            const rects = highlight.position.rects;
            // New word is matched in same line, merge it to last highlight's rect
            if (lastMatchWord.line === ocrWordLine) {
              const lastRect = [...(rects[rects.length - 1].boundingBox || [])];
              lastRect[2] = boundingBox[2];
              lastRect[3] = boundingBox[3];
              lastRect[4] = boundingBox[4];
              lastRect[5] = boundingBox[5];

              const rect = calculateHighlightRect(
                lastRect,
                pageWidth,
                pageHeight,
                ocrWordLine,
              );
              rects.pop();
              rects.push(rect);
            } else {
              // New word is matched in next line, add word's rect
              const rect = calculateHighlightRect(
                boundingBox,
                pageWidth,
                pageHeight,
                ocrWordLine,
              );
              rects.push(rect);
            }
          }

          if (wordIndex === words.length - 1) {
            addHighlight(_ocrHighlights, highlight, pdfHighlighter.viewer);
            found = true;
            highlight = null;
            lastMatchWord = null;
          } else {
            lastMatchWord = ocrWord;
          }
        }

        ocrWordIndex += found ? words.length : 1;
      }

      countMatchedTerm = {};
      setHighlights(_ocrHighlights);
    });
  }, [pdfHighlighter, viewer, searchTerms, positions]);

  return highlights;
};
